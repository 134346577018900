import React, { FC, ReactNode } from "react";

interface Properties {
    children: ReactNode;
    className?: string;
    onClick?: () => void;
}
const H2: FC<Properties> = ({ children, className = "", onClick }: Properties) => (
    <div className="text-white" onClick={onClick}>
        <h2 className={`font-oswald text-2xl font-bold uppercase xl:text-4xl ${className}`}>{children}</h2>
    </div>
);

export default H2;

export interface FilterSingle {
    name: string;
    labels: IFilterLabel;
}
export interface Filter {
    name: string;
    labels: IFilterLabel[];
}

export interface IFilterLabel {
    id: number;
    name: string;
    value?: any;
    show?: boolean;
}

export enum EType {
    overview,
    users,
    videos,
    groups,
    images,
    music,
    podcast,
    companies,
    moviesSeries,
    products,
    posts,
    news,
    places,
    live,
    events,
    multimedia,
}

export const searchFilter = {
    overview: { id: EType.overview, name: "generic.overview" },
    videos: { id: EType.videos, name: "generic.videos" },
    images: { id: EType.images, name: "generic.images" },
    music: { id: EType.music, name: "generic.music" },
    podcast: { id: EType.podcast, name: "generic.podcasts" },
    moviesSeries: { id: EType.moviesSeries, name: "generic.movies_and_series" },
    products: { id: EType.products, name: "generic.products" },
    posts: { id: EType.posts, name: "generic.posts" },
    news: { id: EType.news, name: "generic.news" },
    place: { id: EType.places, name: "generic.places" },
    live: { id: EType.live, name: "generic.live" },
    events: { id: EType.events, name: "generic.events" },
    multimedia: { id: EType.multimedia, name: "generic.multimedia" },
};

export const searchFilterPostType = {
    videos: { id: EType.videos, name: "generic.videos" },
    images: { id: EType.images, name: "generic.images" },
    socials: { id: EType.posts, name: "generic.posts" },
};

export const typeFilter: Filter = {
    name: "Type",
    labels: [
        { id: EType.users, name: "generic.users", show: false },
        { id: EType.videos, name: "generic.videos", show: false },
        { id: EType.groups, name: "generic.groups", show: false },
        { id: EType.images, name: "generic.images", show: false },
        { id: EType.music, name: "generic.music", show: false },
        { id: EType.podcast, name: "generic.podcasts", show: false },
        { id: EType.companies, name: "generic.companies", show: false },
        { id: EType.moviesSeries, name: "generic.movies_and_series", show: false },
        { id: EType.products, name: "generic.products", show: false },
        { id: EType.posts, name: "generic.posts", show: false },
        { id: EType.news, name: "generic.news", show: false },
        { id: EType.places, name: "generic.places", show: false },
        { id: EType.live, name: "generic.live", show: false },
    ],
};

export const sortByFilter: Filter = {
    name: "Sort By",
    labels: [
        { id: 0, name: "Relevance" },
        { id: 0, name: "Date" },
        { id: 0, name: "View Count" },
        { id: 0, name: "Popularity" },
        { id: 0, name: "Rating" },
    ],
};

export enum SearchMultimediaPostType {
    all = "all types",
    videos = "videos",
    images = "images",
}

export enum SearchMultimediaNetwork {
    all = "all networks",
    youtube = "youtube",
    vevo = "vevo",
    pinterest = "pinterest",
    tiktok = "tiktok",
    instagram = "instagram",
    apple = "apple",
    twitter = "X",
}

export enum NewsFilterType {
    All = "Show all",
    Subscriptions = "Subscriptions",
    Hashtags = "Hashtags",
    Interests = "Interests",
    News = "News",
    ECONOMY = "Economy",
    SPORTS = "Sports",
    HEADLINE = "Headline",
    POLITICS = "Politics",
    FASHION = "Fashion",
    STARS = "Stars",
}
